import {
  CommonBG,
  TextWindow,
  MainButton as _Button,
} from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useMatch } from 'react-router-dom';
import styled from 'styled-components';

import { useAdminStageResetMutation } from '../../api/__generated__/game.generated';

type CleanRecordPageProps = Record<string, never>;
const CleanRecord: React.FC<CleanRecordPageProps> = () => {
  const match = useMatch('/admin/:eventId/:uid/cleanRecord');
  const [adminStageResetMutation] = useAdminStageResetMutation();

  React.useEffect(() => {
    if (!match) {
      return;
    }
    const { eventId, uid } = match.params;
    if (!eventId) {
      return;
    }
    if (!uid) {
      return;
    }
    adminStageResetMutation({
      variables: {
        input: {
          stageId: '2',
          uid: uid,
          eventId: eventId,
        },
      },
    });
  }, [match]);

  return (
    <CommonBG>
      <Wrapper>
        <Text>{'記録の消去が完了しました。'}</Text>
      </Wrapper>
    </CommonBG>
  );
};

export default CleanRecord;

const Wrapper = styled.div`
  position: relative;
  padding-top: 8rem;
`;

const Text = styled(TextWindow)`
  line-height: 4rem;
  margin: 0 auto;
  padding: 6rem;
`;

const Button = styled(_Button)`
  display: block;
  margin: 3rem auto 4rem;
`;
