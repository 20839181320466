import { useSelector } from 'react-redux';
import { useMatch } from 'react-router-dom';

import { StageId } from '../../api/stages';

export const useStageState = (props: { stageId: StageId }) =>
  useSelector(state => ({
    finished: state.game.hasStageFinished[props.stageId],
  }));
export const useLocalStage4Penalty = () =>
  useSelector(state => state.game.stage4Penalty);
export const useStagesFinished = () =>
  useSelector(state => state.game.hasStageFinished);
export const useEventId = () =>
  useMatch('/events/:eventId/*')?.params.eventId ?? null;
export const useTrainingId = () =>
  useMatch('/trainings/:trainingId/*')?.params.trainingId ?? null;
export const useAdminEventId = () =>
  useMatch('/admin/events/:eventId/*')?.params.eventId ?? null;
export const useAdminTrainingId = () =>
  useMatch('/admin/training/:trainingId/*')?.params.trainingId ?? null;
export const useAdminOrgId = () =>
  useMatch('/admin/organization/:orgId')?.params.orgId ?? null;
export const useAdminResultParams = () => {
  const match = useMatch('/admin/events/:eventId/result/:uid');
  return {
    eventId: match?.params.eventId ?? null,
    uid: match?.params.uid ?? null,
  };
};
export const useStage5GroupInfo = () =>
  useSelector(state => state.game.stage5GroupInfo);
export const useStage5CurrentAnswer = () =>
  useSelector(state => state.game.stage5CurrentAnswer);
export const useRecordedStartTimes = () =>
  useSelector(state => state.game.recordedStageStartTimes);
export const useRecordedStartTime = (stageId: StageId) =>
  useSelector(state => state.game.recordedStageStartTimes[stageId]);
export const useStageManagerState = () =>
  useSelector(state => state.game.stageManagerState);
